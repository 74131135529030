export { PermissionProvider } from './Permission'

export enum PermissionAccess {
  Read = 'r',
  Write = 'w',
}

export enum PermissionKey {
  mn_home = 'mn_home',
  mn_upload = 'mn_upload',
  mn_data_input = 'mn_data_input',
  mn_npi_eligibility = 'mn_npi_eligibility',
  mn_category_classification = 'mn_category_classification',
  mn_patient_source = 'mn_patient_source',
  mn_source_mngt = 'mn_source_mngt',
  mn_fee_calc = 'mn_fee_calc',
  mn_field_conf = 'mn_field_conf',
  mn_fee_mngt = 'mn_fee_mngt',
  mn_support = 'mn_support',
  mn_admin = 'mn_admin',
  mn_sync = 'mn_sync',
  mn_user_roles_mngt = 'mn_user_roles_mngt',
  mn_aux_rules_mngt = 'mn_aux_rules_mngt',
  mn_ad_mngt = 'mn_ad_mngt',
  mn_users = 'mn_users',
  mn_ce = 'mn_ce',
  mn_memberships = 'mn_memberships',
  mn_pharmasee = 'mn_pharmasee',

  tb_upload_file = 'tb_upload_file',
  list_upload_type = 'list_upload_type',
  btn_download_template = 'btn_download_template',
  aux_file_upload = 'aux_file_upload',

  tb_upload_history = 'tb_upload_history',

  tb_fd_flags_conf = 'tb_fd_flags_conf',
  flags_mngt = 'flags_mngt',

  tb_fd_rates_conf = 'tb_fd_rates_conf',
  rates_mngt = 'rates_mngt',

  tb_fd_ranges_conf = 'tb_fd_ranges_conf',
  ranges_mngt = 'ranges_mngt',

  tb_fee_mngt = 'tb_fee_mngt',
  btn_virtual_ce = 'btn_virtual_ce',
  btn_edit_mapping = 'btn_edit_mapping',

  fee_mngt = 'fee_mngt',
  mapping_mngt = 'mapping_mngt',

  tb_draft_mngt = 'tb_draft_mngt',
  drafts_mngt = 'drafts_mngt',

  tab_permissions = 'tab_permissions',
  permissions_mngt = 'permissions_mngt',

  tab_resources = 'tab_resources',
  resources_mngt = 'resources_mngt',

  tab_validation_rules = 'tab_validation_rules',

  tab_upload_template = 'tab_upload_template',

  btns_users = 'btns_users',
  btns_ce_mngt = 'btns_ce_mngt',
  btns_memberships = 'btns_memberships',

  btns_category_classification = 'btns_category_classification',

  btns_source_mngt = 'btns_source_mngt',
  btns_npi_eligibility = 'btns_npi_eligibility',
}
